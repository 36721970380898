/*
name: main.js
discription: Javascript Datei
required: functions.js
Author: Robert Menghini
*/

import UIkit from '@node_modules/uikit';

import $ from '../vendors/jquery-3.2.1.min';

window.jQuery = $;
window.$ = $;

'use strict';

//UIkit.offcanvas("#offcanvas").show();

/**
 * Geraetespezifische Klassen ins html-Tag setzen
*/

(function(){
   var 	isMobile, 
         isIOS,
         ua = navigator.userAgent;


   var html =  document.documentElement;

   html.classList.remove('cc-no-js');
   html.classList.add('cc-js');
   
   isMobile = /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera Mini/i.test(ua);
   isIOS = /iPhone|iPad|iPod/i.test(ua);
   
   if ( isMobile ) {
      html.classList.remove('cc-desktop');
      html.classList.add('cc-mobile');
   }//endif

   if ( isIOS ) {
      html.classList.add('cc-iOS');
   }//endif
})();

/**
 * Toggle expand content
*/

(function(){

   var Expands = $('.cc-expand');

   if (!Expands.length) return;

   $('.cc-expand-cover').css('display', 'block');

   var   ClassInit = 'cc-expand--init',
         ClassCollapsed = 'cc-expand--collapsed';

   var ToggleExpandButton = $('.ccDyn-ToggleExpand');

   var curHeight = 0; // init

   Expands.addClass(ClassInit + ' ' + ClassCollapsed);

   ToggleExpandButton.click(function(e) {

      e.preventDefault();

      var   Clicked = $(this),
            ParentExpand = Clicked.parents('.cc-expand'),
            Cover = ParentExpand.children('.cc-expand-content').children('.cc-expand-cover');

      var _this = this; //workaround

      if ( Cover.is(':visible')) {

          //Workaround animate height:auto ( height:auto not working in jquery animation)
         curHeight = ParentExpand.height(); // Save the current height
         ParentExpand.css({ height: 'auto' }); // Temporarily switch the height to auto
         var autoHeight = ParentExpand.height(); // Get the auto height
         ParentExpand.height(curHeight).animate({ height: autoHeight }, 'slow'); // Switch back to curHeight and animate to autoHeight
          

         Cover.fadeOut('slow', function(){
            $(_this).attr('data-uk-icon', 'icon: chevron-up; ratio: 3');
         });
         

      } else {

         ParentExpand.animate({height: curHeight}, 'slow');
         Cover.fadeIn('slow', function() {
            $(_this).attr('data-uk-icon', 'icon: chevron-down; ratio: 3');
         });
        

      }//endif

   });
})();


/**
 * Remove hash from URL
*/


(function(){

   var hash = window.location.hash;

   if ( !hash ) return;

   var PageUrl = window.location.href.split('#')[0];

   window.onload = function (event) {

      window.location.hash = hash;

      if ('replaceState' in history) {
         history.replaceState(null, '', PageUrl);
      }//endif
   }

})();


/**
 * Close Offcanvas Nav on Subnav-Link click and update hash
*/

(function(){   

   var OffcanvasNav = document.getElementById('offcanvas');

   UIkit.util.on('.ccDyn-scrollTrigger', 'scrolled', function () {

      UIkit.offcanvas(OffcanvasNav).hide();

   });

})();